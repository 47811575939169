import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import { apiReportDishHisDishDel } from '@/request/dishApi';

export default (getList) => {

    function onDelete(row) {
        ElMessageBox.confirm(
            '确认删除？',
            '提示',
            {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
            }
        ).then(() => {
            deleteFn(row)
        })
    }

    async function deleteFn(row) {
        const loading = ElLoading.service();
        const { code } = await apiReportDishHisDishDel({ id: row.id });
        loading.close();
        if (code !== '0') return;
        ElMessage({ type: 'success', message: '删除成功' })
        getList();
    }

    return { onDelete }
}