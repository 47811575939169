/**
 * api统一管理
 */
import { get, post } from './http';

/**
 * 字典
 */
// 字典查询
export const dishDict = (p) => post('/api/hot/dish/dict', p);
// 系统字典查询
export const dishSystemDict = (p) => post('/api/hot/dish/system/dict', p);

/**
 * 大区管理
 */
// 大区模板-获取大区模板列表
export const templateGetList = (p) => get('/api/hot/template/getList', p);
// 大区模板-新建大区模板
export const templateSave = (p) => post('/api/hot/template/save', p);
// 大区模板-删除大区模板
export const templateDel = (p) => post('/api/hot/template/del', p);
// 大区模板-获取大区模板详情
export const templateInfo = (p) => get('/api/hot/template/info', p);
// 大区模板-修改大区模板
export const templateUpdate = (p) => post('/api/hot/template/update', p);
// 大区设置-获取大区列表
export const regionGetList = (p) => get('/api/hot/region/getList', p);
// 大区设置-新建大区
export const regionSave = (p) => post('/api/hot/region/save', p);
// 大区设置-修改大区
export const regionUpdate = (p) => post('/api/hot/region/update', p);
// 大区设置-删除大区
export const regionDel = (p) => post('/api/hot/region/del', p);

/**
 * 报告
 */
// 报告-获取历史报告列表
export const reportGetList = (p) => get('/api/hot/report/getList', p);
// 报告-修改报告
export const reportUpdate = (p) => post('/api/hot/report/update', p);
// 报告-报告详情
export const reportGetInfo = (p) => get('/api/hot/report/getInfo', p);
// 报告-新建报告
export const reportSave = (p) => post('/api/hot/report/save', p);
// 报告-历史报告新建
export const reportHisSave = (p) => post('/api/hot/report/hisSave', p);
// 报报告-获取报告结果
export const reportResult = (p) => get('/api/hot/report/result', p);
// 报告-检测报告完整性
export const reportCheck = (p) => get('/api/hot/report/check', p);
// 报告-完成报告
export const reportComplete = (p) => post('/api/hot/report/complete', p);
// 报告-删除报告
export const reportDel = (p) => post('/api/hot/report/del', p);
// 历史合并菜-列表数据查询
export const apiReportDishHisDish = (p) => get('/api/hot/report/dish/hisDish', p);
// 历史合并菜-excl数据导出
export const apiReportDishHisDishExcl = () => '/api/hot/report/dish/hisDish/excl';
// 历史合并菜-新增
export const apiReportDishHisDishSave = (p) => post('/api/hot/report/dish/hisDish/save', p);
// 历史合并菜-修改
export const apiReportDishHisDishUpdate = (p) => post('/api/hot/report/dish/hisDish/update', p);
// 历史合并菜-删除
export const apiReportDishHisDishDel = (p) => post('/api/hot/report/dish/hisDish/del', p);

/**
 * 报告页
 */
// 报告页-获取报告页详情
export const reportPageGetInfo = (p) => get('/api/hot/report/page/getInfo', p);
// 报告页-列表菜品查询(搜索)
export const reportDishList = (p) => post('/api/hot/report/dish/list', p);
// 报告页-列表菜品查询（历史数据）
export const reportDishHisList = (p) => get('/api/hot/report/dish/hisList', p);
// 报告页-修改报告页（名称）
export const reportPageUpdate = (p) => post('/api/hot/report/page/update', p);
// 报告页-新建报告页
export const reportPageSave = (p) => post('/api/hot/report/page/save', p);
// 报告页-删除报告页
export const reportPageDel = (p) => post('/api/hot/report/page/del', p);
// 报告页-excl数据导出
export const reportDishExport = () => '/api/hot/report/dish/export';
// 报告页-删除热菜
export const reportDishDel = (p) => post('/api/hot/report/dish/del', p);
// 报告页-批量删除热菜
export const reportDishBatchDel = (p) => post('/api/hot/report/dish/batchDel', p);
// 报告页-撤销删除热菜
export const reportDishCancelDel = (p) => post('/api/hot/report/dish/cancelDel', p);
// 报告页-查询合并菜列表数据
export const reportDishGetMergeData = (p) => get('/api/hot/report/dish/getMergeData', p);
// 报告页-合并热菜
export const reportDishMerge = (p) => post('/api/hot/report/dish/merge', p);
// 报告页-撤销合并热菜
export const reportDishCancelMerge = (p) => post('/api/hot/report/dish/cancelMerge', p);
// 报告页-获取热菜图片
export const reportDishGetDishImg = (p) => get('/api/hot/report/dish/getDishImg', p);
// 报告页-选择热菜图片
export const reportDishPickingImage = (p) => post('/api/hot/report/dish/pickingImage', p);
// 报告页-获取报告页下拉列表
export const reportPageDict = (p) => get('/api/hot/report/page/dict', p);
// 报告生成配置-获取生成配置详情
export const reportConfigGetInfo = (p) => get('/api/hot/report/config/getInfo', p);
// 告生成配置-新建&修改
export const reportConfigSave = (p) => post('/api/hot/report/config/save', p);
// 报告页-保存合并搜索关键词
export const reportDishMergeSearch = (p) => get('/api/hot/report/dish/mergeSearch', p);





