<template>
    <el-dialog :model-value="show" :title="title" width="500px" @close="closeHandle" @opened="openedHandle">
        <el-form ref="formRef" :model="state.form" :rules="state.rules" label-width="90px" label-position="left">
            <el-form-item prop="dishName" label="原始菜名">
                <el-input v-model.trim="state.form.dishName" placeholder="请输入" />
            </el-form-item>
            <el-form-item prop="mergeName" label="合并菜名">
                <el-input v-model.trim="state.form.mergeName" placeholder="请输入" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm(formRef)">确定</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script setup>
import { type } from 'os';
import { computed, reactive, ref } from 'vue';

const props = defineProps({
    show: { type: Boolean, required: true },
    type: { type: String, default: 'add' },
    row: { type: Object, default: {} }
})

const emits = defineEmits(['submit', 'close'])

const formRef = ref(null)

const state = reactive({
    form: { dishName: '', mergeName: '' },
    rules: { dishName: [{ required: true, message: '请输入原始菜名', trigger: 'blur' }], mergeName: [{ required: true, message: '请输入合并菜名', trigger: 'blur' }] }
})

const title = computed(() => {
    const obj = { add: '新增', edit: '编辑' };
    return obj[props.type]
})

// 打开
function openedHandle() {
    if (type === 'add') return;
    state.form.dishName = props.row.dishName;
    state.form.mergeName = props.row.mergeName;
}

// 关闭
function closeHandle() {
    formRef.value.resetFields();
    emits('close');
}

// 提交
function submitForm(formEl) {
    if (!formEl) return;
    formEl.validate((valid) => {
        if (valid) {
            emits('submit', state.form)
        }
    })
}
</script>