import { reactive } from "vue";
import { apiReportDishHisDishSave, apiReportDishHisDishUpdate } from '@/request/dishApi';
import { ElMessage, ElLoading } from 'element-plus';

// 重命名弹框
export default (getList) => {
    const state = reactive({
        show: false,
        type: 'add',
        row: {}
    })

    // 打开
    function open(type, row) {
        state.type = type;
        state.row = row;
        state.show = true;
    }

    // 关闭
    function close() {
        state.show = false;
        state.row = {};
        state.type = 'add';
    }

    // 提交
    async function submit(form) {
        switch (state.type) {
            case 'add':
                addFn(form)
                break;
            case 'edit':
                editFn(form)
                break;
        }
    }

    // 新增
    async function addFn(form) {
        const loading = ElLoading.service();
        const { code } = await apiReportDishHisDishSave(form);
        loading.close();
        if (code !== '0') return;
        ElMessage({ type: 'success', message: '操作成功' })
        getList();
        close();
    }

    // 编辑
    async function editFn(form) {
        const loading = ElLoading.service();
        const { code } = await apiReportDishHisDishUpdate({ ...form, id: state.row.id });
        loading.close();
        if (code !== '0') return;
        ElMessage({ type: 'success', message: '操作成功' })
        getList();
        close();
    }

    return { state, open, close, submit }
}