<template>
    <div class="history_page" v-loading="state.loading">
        <section class="list_box">
            <div class="tit_box">
                <small-title tit="历史合并菜"></small-title>
            </div>
            <div class="search_box">
                <div class="le_box">
                    <div class="item_box">
                        <span>原始菜名</span>
                        <el-input v-model.trim="state.query.dishName"></el-input>
                    </div>
                    <div class="item_box">
                        <span>合并后菜名</span>
                        <el-input v-model.trim="state.query.mergeName"></el-input>
                    </div>
                    <div class="item_box">
                        <span>合并菜名自定义</span>
                        <el-radio-group v-model="state.query.customFlag">
                            <el-radio-button label="">不限</el-radio-button>
                            <el-radio-button :label="0">否</el-radio-button>
                            <el-radio-button :label="1">是</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="item_box">
                        <el-button type="primary" @click="searchFn">搜索</el-button>
                    </div>
                </div>
                <div class="ri_box">
                    <el-button type="primary" @click="adOpen('add')">新增</el-button>
                    <el-button type="primary" @click="exportExcelFn" :loading="exportLoading">下载excel</el-button>
                </div>
            </div>
            <div class="table_box">
                <el-table :data="state.tableData" stripe border style="width: 100%"
                    :header-cell-style="{ background: '#f5f5f5', color: '#80899b', fontWeight: 400 }">
                    <el-table-column label="序号" width="50" align="center">
                        <template #default="scope">
                            {{ (state.query.pageNum - 1) * state.query.pageSize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="dishName" label="原始菜名" align="center" />
                    <el-table-column prop="mergeName" label="合并后的菜名" align="center" />
                    <el-table-column prop="customFlag" label="合并菜是否自定义" align="center">
                        <template #default="scope">
                            {{ scope.row.customFlag === 0 ? '否' : '是' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="num" label="合并次数" align="center" />
                    <el-table-column prop="lastMergeDate" label="最后一次合并时间" align="center" />
                    <el-table-column label="操作" width="100px">
                        <template #default="scope">
                            <el-button type="text" @click="adOpen('edit', scope.row)">编辑</el-button>
                            <el-button type="text" style="color: #F56C6C;" @click="onDelete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="暂无数据"></el-empty>
                    </template>
                </el-table>
            </div>
            <div class="page_box">
                <el-pagination layout="prev, pager, next" v-model:current-page="state.query.pageNum" background
                    :page-size="state.query.pageSize" :total="state.total"></el-pagination>
            </div>
        </section>
    </div>
    <!-- 新增/编辑弹窗 -->
    <add-edit-dialog :show="adState.show" :type="adState.type" :row="adState.row" @close="adClose" @submit="adSubmit">
    </add-edit-dialog>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
import SmallTitle from '@/components/common/SmallTitle.vue';
import { apiReportDishHisDish } from '@/request/dishApi';
import useExport from './useExport';
import AddEditDialog from './add-edit/AddEditDialog.vue';//新增/编辑弹窗
import useAddEdit from './add-edit/useAddEdit';
import useDelete from './useDelete';// 删除

const state = reactive({
    loading: false,
    query: {
        dishName: '',
        mergeName: '',
        customFlag: '',
        pageNum: 1,
        pageSize: 10
    },
    tableData: [],
    total: 0,
})

// 获取列表
async function getList() {
    state.loading = true;
    const { data } = await apiReportDishHisDish(state.query)
    state.loading = false;
    state.tableData = data?.list || [];
    state.total = data?.total || 0;
}

// 分页
watch(() => state.query.pageNum, () => { getList() })

// 搜索
function searchFn() {
    state.query.pageNum = 1;
    getList()
}

// 导出
const { exportExcelFn, loading: exportLoading } = useExport(state);

// 新增/编辑
const { state: adState, open: adOpen, close: adClose, submit: adSubmit } = useAddEdit(getList);

// 删除
const { onDelete } = useDelete(getList);

onBeforeMount(() => {
    getList()
})
</script>

<style scoped lang="scss">
.history_page {
    padding: 20px 40px;

    .list_box {
        background-color: #fff;
        border-radius: 14px;
        padding: 20px;

        .tit_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .search_box {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;

            .le_box {
                display: flex;
                flex-wrap: wrap;

                .item_box {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    span {
                        white-space: nowrap;
                        color: #666;
                        padding-right: 5px;
                    }
                }
            }
        }

        .table_box {
            padding-top: 20px;
        }

        .page_box {
            text-align: right;
            padding: 20px 0;
        }
    }
}
</style>