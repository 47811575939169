import { apiReportDishHisDishExcl } from '@/request/dishApi';
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";
import { ref } from 'vue';
import { ElMessage } from 'element-plus';

export default (indexState) => {
    const loading = ref(false);
    // 导出请求
    async function exportExcelFn() {
        if (indexState.tableData.length === 0) {
            ElMessage.error('数据为空');
            return;
        }
        loading.value = true;
        const token = getStorage("token");
        let href = `${baseURL}${apiReportDishHisDishExcl()}?token=${token}`;
        href += `&dishName=${indexState.query.dishName}`;
        href += `&mergeName=${indexState.query.mergeName}`;
        href += `&customFlag=${indexState.query.customFlag}`;
        const a = document.createElement("a");
        a.href = href;
        a.download = href;
        a.click();
        setTimeout(() => {
            loading.value = false;
        }, 1500);
    }
    return { exportExcelFn, loading }
}